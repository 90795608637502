import React, { useState } from "react";
import styled from "@mui/system/styled";
import { useCallCenterForm } from "./hooks/useCallCenterForm";

import Address1 from "./inputs/address1";
import Address2 from "./inputs/address2";
import City from "./inputs/city";
import Email from "./inputs/email";
import FirstName from "./inputs/firstName";
import LastName from "./inputs/lastName";
import Phone from "./inputs/phone";
import State from "./inputs/state";
import Zip from "./inputs/zip";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import CreditCard from "./components/CreditCard";

const TextInput = styled(TextField)(() => ({
  padding: "4px",
}));

const OrderForm = () => {
  const { errors, onSubmit } = useCallCenterForm();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%", overflowX: "scroll" }}
    >
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <Stack direction="column" justifyContent="center">
          <div style={{ display: "flex" }}>
            <FirstName />
            <LastName />
          </div>

          <Address1 />
          <Address2 />

          <div style={{ display: "flex" }}>
            <City />
            <State />
            <Zip />
          </div>

          <div style={{ display: "flex" }}>
            <Email />
            <Phone />
          </div>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "2px solid blue",
              borderRadius: "8px",
              padding: "6px",
            }}
          >
            <CreditCard />
          </div>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              display: "none",
            }}
          >
            <TextInput
              id="standard-basic"
              disabled
              label="Country Code (Hidden)"
            />
            <TextInput id="standard-basic" disabled label="Source (Hidden)" />
            <TextInput id="standard-basic" disabled label="SKU (Hidden)" />
            <TextInput
              id="standard-basic"
              disabled
              label="CVV Indicator (Hidden)"
            />
            <TextInput
              id="standard-basic"
              disabled
              label="Do Not Call (Hidden)"
            />
            {/* <TextInput
              id="standard-basic"
              disabled
              label="Customer Order Number (Hidden)"
            /> */}
            {/* Sempris orderId (POP ReferenceId) */}
            <TextInput
              id="standard-basic"
              disabled
              label="Sales Flag (Hidden)"
            />
            <TextInput
              id="standard-basic"
              disabled
              label="Seed Indicator (Hidden)"
            />
            <TextInput
              id="standard-basic"
              disabled
              label="Client Data (Hidden)"
            />
            <TextInput
              id="standard-basic"
              disabled
              label="Savvy Pitch Id (Hidden)"
            />
          </div>
        </Stack>
        <Button type="submit" variant="contained" disabled={!errors}>
          Submit
        </Button>
      </form>
    </Stack>
  );
};

export default OrderForm;
