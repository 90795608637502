import React from "react";
import styled from "@mui/system/styled";
import { useCallCenterForm } from "./hooks/useCallCenterForm";

import OrderForm from "./OrderForm";
import WaitModal from "./components/WaitModal";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const FullPageBox = styled(Box)(() => ({
  height: "100vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const Main = () => {
  const { hasPrePopulated, isLoading, validInitialForm } = useCallCenterForm();

  if (!validInitialForm) {
    return (
      <FullPageBox>
        <p>Form Is invalid</p>
        <p>URL Must include a client, source, and sku</p>
        <pre>https://vendor.host.com?source=exampleSource&sku=exampleSku</pre>
      </FullPageBox>
    );
  }

  if (isLoading || !hasPrePopulated) {
    return (
      <FullPageBox>
        <CircularProgress />
      </FullPageBox>
    );
  }

  return (
    <>
      <WaitModal />
      <OrderForm />
    </>
  );
};

export default Main;
