import { configureStore } from "@reduxjs/toolkit";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { catchError } from "rxjs/operators";

const epicMiddleware = createEpicMiddleware();

// SLICES
import formSlice from "../containers/form/formSlice";

// EPICS
import formEpics from "../containers/form/formEpics";

const epics = combineEpics(...formEpics);

const rootEpic = (action$, store$, dependencies) =>
  epics(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );

export const store = configureStore({
  reducer: {
    formSlice,
  },
  middleware: [epicMiddleware],
});

epicMiddleware.run(rootEpic);
