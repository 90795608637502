import React, { useEffect } from "react";
import styled from "@mui/system/styled";
import { useCallCenterForm } from "../hooks/useCallCenterForm";

import TextField from "@mui/material/TextField";

const TextInput = styled(TextField)(() => ({
  padding: "4px",
}));

const Address1 = () => {
  const { errors, formValues, handleInputChange, handleInputFocus } =
    useCallCenterForm();

  useEffect(() => {}, [formValues]);

  const handleErrorText = () => {
    if (!errors?.address1) {
      return null;
    }

    return errors?.address1;
  };

  return (
    <TextInput
      sx={{ flex: 1 }}
      name="address1"
      label="Address 1"
      onChange={handleInputChange}
      onFocus={handleInputFocus}
      value={formValues?.address1}
      helperText={handleErrorText()}
      error={!!errors?.address1}
    />
  );
};

export default Address1;
