import React from "react";

import Main from "./Main";
import { CallCenterFormProvider } from "./hooks/useCallCenterForm";

const MainRoute = () => {
  return (
    <CallCenterFormProvider>
      <Main />
    </CallCenterFormProvider>
  );
};

export default MainRoute;
