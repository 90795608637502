import React, { useEffect } from "react";
import styled from "@mui/system/styled";
import { PatternFormat } from "react-number-format";
import { useCallCenterForm } from "../hooks/useCallCenterForm";

import TextField from "@mui/material/TextField";

const TextInput = styled(TextField)(() => ({
  padding: "4px",
}));

const CustomFormatCVV = React.forwardRef((props, ref) => {
  const { formValues } = useCallCenterForm();
  const { onChange, ...other } = props;
  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={formValues?.issuer === "american-express" ? "####" : "###"}
    />
  );
});

const CardCVV = () => {
  const { errors, formValues, handleInputChange, handleInputFocus } =
    useCallCenterForm();

  useEffect(() => {}, [formValues]);

  const handleErrorText = () => {
    if (!errors?.card_cvv) {
      return null;
    }

    return errors?.card_cvv;
  };

  return (
    <TextInput
      sx={{ flex: 1 }}
      name="card_cvv"
      label="CVV"
      value={formValues?.card_cvv}
      InputProps={{
        inputComponent: CustomFormatCVV,
      }}
      inputProps={{
        inputMode: "numeric",
      }}
      onChange={handleInputChange}
      onFocus={handleInputFocus}
      helperText={handleErrorText()}
      error={!!errors.card_cvv}
    />
  );
};

export default CardCVV;
