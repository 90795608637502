import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const baseTheme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: '#e5e5e5'
    },
    disabled: {
      light: '#e5e5e5',
      main: '#f9f9f9'
    },
    success: {
      main: '#58a758'
    },
    error: {
      main: '#cd5c5c'
    },
    warning: {
      main: '#cbcb04'
    },
    common: {
      white: '#ffffff',
      black: '#000000'
    },
    primary: {
      main: '#1c3669',
    },
    secondary: {
      main: '#5c709f',
    },
		custom: {
			hierarchy: '#a569bd'
		}
  }
});

const responsiveTheme = responsiveFontSizes(baseTheme);

export default baseTheme;
