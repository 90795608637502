import axios from "axios";

import config from "../config.json";

const instance = axios.create({
  baseURL: config[process.env.REACT_APP_ENV].api,
});

instance.defaults.withCredentials = false;

const setAuth = (token) => {
  instance.defaults.headers.common.Authorization = token;
};

const getAuth = () => {
  return instance.defaults.headers.common.Authorization;
};

export default instance;
export { getAuth, setAuth };
