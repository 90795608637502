import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { validateForm } from "../validation/formValidation";
import { validateUrl } from "../../../utils/validateUrl";
import { submitForm } from "../../../containers/form/formSlice";

const CallCenterFormContext = createContext();

const CallCenterFormContextProvider = ({ children }) => {
  /* ====================== HOOKS ====================== */
  const isLoading = false;
  const dispatch = useDispatch();

  const [validInitialForm, setValidInitialForm] = useState(false);
  const [hasPrePopulated, setHasPrePopulated] = useState(false);
  const [errors, setErrors] = useState({});

  const [formValues, setFormValues] = useState({});
  const [searchParams] = useSearchParams();

  /* ====================== EFFECTS ====================== */
  useEffect(() => {
    const host = window.location.host;
    if (validateUrl(host)) {
      setValidInitialForm(true);
    } else {
      setValidInitialForm(false);
    }
  }, []);

  useEffect(() => {
    if (!hasPrePopulated) {
      const prePopValues = {};
      for (const entry of searchParams.entries()) {
        const [param, value] = entry;

        if (param === "state") {
          prePopValues[param] = value.toUpperCase();
        } else if (param === "card_cvv") {
          prePopValues[param] = "";
        } else {
          prePopValues[param] = value;
        }
      }

      if (!prePopValues.source || !prePopValues.sku) {
        setValidInitialForm(false);
      } else {
        const vendor_id = window.location.host.split(".")[0];
        prePopValues.vendor_id = vendor_id;

        setFormValues(prePopValues);
        setHasPrePopulated(true);
      }
    }
  }, [hasPrePopulated]);

  /* ====================== METHODS ====================== */
  const runValidation = () => {
    const validationFailed = validateForm(formValues);
    console.log("FAIL?", validationFailed);

    if (validationFailed) {
      const validationErrors = {};
      validationFailed.map((entry) => {
        validationErrors[entry.path[0]] = entry.message;
      });
      setErrors(validationErrors);
    } else {
      setErrors({});
    }

    return !!validationFailed;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const validationFailed = runValidation();
    if (validationFailed) {
      return;
    }

    dispatch(submitForm(formValues));
  };

  const setFormValuesManually = (key, value) => {
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputFocus = (evt) => {
    setFormValues((prev) => ({ ...prev, focus: evt.target.name }));
  };

  const resetFormValues = () => {
    const newFormValues = {
      cvv: "",
    };
    for (const [key, value] of Object.entries(formValues)) {
      newFormValues[key] = "";
    }

    setFormValues(newFormValues);
  };

  /* ====================== MEMO ====================== */
  const value = useMemo(
    () => ({
      errors,
      formValues,
      handleInputChange,
      handleInputFocus,
      onSubmit,
      hasPrePopulated,
      isLoading,
      resetFormValues,
      setFormValuesManually,
      validInitialForm,
    }),
    [errors, formValues, setFormValues, validInitialForm]
  );

  /* ====================== RENDER ====================== */
  return (
    <CallCenterFormContext.Provider value={value}>
      {children}
    </CallCenterFormContext.Provider>
  );
};

export const CallCenterFormProvider = CallCenterFormContextProvider;

export const useCallCenterForm = () => {
  return useContext(CallCenterFormContext);
};
