import { createBrowserRouter } from 'react-router-dom';

import CenteredLayout from '../layouts/CenteredLayout';

// Routes
import Main from '../routes/Main';

const router = createBrowserRouter([
  {
    path: '/',
    element: <CenteredLayout Component={Main} />,
  }
]);

export default router;
