import React, { useEffect } from "react";
import styled from "@mui/system/styled";
import { PatternFormat } from "react-number-format";
import { useCallCenterForm } from "../hooks/useCallCenterForm";

import TextField from "@mui/material/TextField";

const TextInput = styled(TextField)(() => ({
  padding: "4px",
}));

const CustomFormatPhone = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={"(###) ###-####"}
      mask="_"
    />
  );
});

const Phone = () => {
  const { errors, formValues, handleInputChange, handleInputFocus } =
    useCallCenterForm();

  useEffect(() => {}, [formValues]);

  const handleErrorText = () => {
    if (!errors?.phone) {
      return null;
    }

    return errors?.phone;
  };

  return (
    <TextInput
      sx={{ flex: 1 }}
      name="phone"
      label="Phone"
      value={formValues?.phone}
      onChange={handleInputChange}
      onFocus={handleInputFocus}
      InputProps={{
        inputComponent: CustomFormatPhone,
      }}
      helperText={handleErrorText()}
      error={!!errors?.phone}
    />
  );
};

export default Phone;
