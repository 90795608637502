import React, { useEffect } from "react";
import styled from "@mui/system/styled";
import { PatternFormat } from "react-number-format";
import { useCallCenterForm } from "../hooks/useCallCenterForm";

import TextField from "@mui/material/TextField";

const TextInput = styled(TextField)(() => ({
  padding: "4px",
}));

const CustomFormatCardNumber = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  const { formValues } = useCallCenterForm();

  useEffect(() => {}, [formValues]);

  const setPatternFormat = () => {
    switch (formValues?.issuer) {
      case "discover":
      case "jcb":
      case "mastercard":
      case "dankort":
      case "maestro":
      case "visa":
        return "#### #### #### ####";
      case "american-express":
        return "#### ###### #####";
      case "diners-club":
        return "#### ###### ####";
      default:
        return "###################";
    }
  };

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={setPatternFormat()}
      mask="_"
    />
  );
});

const CardNumber = () => {
  const { errors, formValues, handleInputChange, handleInputFocus } =
    useCallCenterForm();

  const handleErrorText = () => {
    if (!errors?.card_number) {
      return null;
    }

    return errors?.card_number;
  };

  return (
    <TextInput
      sx={{ flex: 1 }}
      name="card_number"
      label="Card Number"
      value={formValues?.card_number}
      InputProps={{
        inputComponent: CustomFormatCardNumber,
      }}
      inputProps={{
        inputMode: "numeric",
      }}
      error={!!errors?.card_number}
      onChange={handleInputChange}
      onFocus={handleInputFocus}
      helperText={handleErrorText()}
    />
  );
};

export default CardNumber;
