import React, { useEffect } from "react";
import styled from "@mui/system/styled";
import { useCallCenterForm } from "../hooks/useCallCenterForm";

import TextField from "@mui/material/TextField";

const TextInput = styled(TextField)(() => ({
  padding: "4px",
}));

const City = () => {
  const { errors, formValues, handleInputChange, handleInputFocus } =
    useCallCenterForm();

  useEffect(() => {}, [formValues]);

  const handleErrorText = () => {
    if (!errors?.city) {
      return null;
    }

    return errors?.city;
  };

  return (
    <TextInput
      sx={{ flex: 1 }}
      name="city"
      label="City"
      onChange={handleInputChange}
      onFocus={handleInputFocus}
      value={formValues?.city}
      helperText={handleErrorText()}
      error={!!errors?.city}
    />
  );
};

export default City;
