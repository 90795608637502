import React, { useEffect } from "react";
import Cards from "react-credit-cards-2";
import { useCallCenterForm } from "../hooks/useCallCenterForm";

import CardNumber from "../inputs/cardNumber";
import CardExpiration from "../inputs/cardExpiration";
import CardCVV from "../inputs/cardCVV";

const CreditCard = () => {
  const { formValues, setFormValuesManually } = useCallCenterForm();

  useEffect(() => {}, [formValues]);

  const setCardType = (e) => {
    if (formValues.issuer !== e.issuer) {
      setFormValuesManually("issuer", e.issuer);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Cards
        number={formValues.card_number || ""}
        expiry={formValues.card_expiration || ""}
        cvc={formValues.card_cvv || ""}
        name={`${formValues.first_name || ""} ${formValues.last_name || ""}`}
        focused={formValues.focus === "card_cvv" ? "cvc" : formValues.focus}
        callback={setCardType}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          margin: "10px",
        }}
      >
        <CardNumber />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CardExpiration />
          <CardCVV />
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
