import React from "react";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import router from "./router";
import Theme from "./theme/Theme";
import { store } from "./store";

import "./index.css";
import "react-credit-cards-2/dist/es/styles-compiled.css";

function App() {
  return (
    <Provider store={store}>
      <Theme>
        <RouterProvider router={router} />
      </Theme>
    </Provider>
  );
}

export default App;
