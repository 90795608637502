import React, { useEffect, useState } from "react";
import { useCallCenterForm } from "../hooks/useCallCenterForm";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const State = () => {
  const { errors, formValues, handleInputChange, handleInputFocus } =
    useCallCenterForm();

  const [stateSelected, setStateSelected] = useState(formValues?.state || "");

  useEffect(() => {}, [formValues]);

  const handleErrorText = () => {
    if (!errors?.state) {
      return null;
    }

    return errors?.state;
  };

  const onSelect = (e) => {
    handleInputChange(e);
    setStateSelected(e.target.value);
  };

  const states = [
    "AL",
    "AK",
    "AS",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FM",
    "FL",
    "GA",
    "GU",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MH",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "MP",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VI",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  return (
    <FormControl sx={{ flex: 1 }} error={!!errors?.state}>
      <InputLabel>State</InputLabel>
      <Select
        sx={{ margin: "4px" }}
        name="state"
        label="State"
        onChange={onSelect}
        onFocus={handleInputFocus}
        value={stateSelected}
        inputProps={{
          inputMode: "numeric",
        }}
        error={!!errors?.state}
      >
        {states.map((state, idx) => (
          <MenuItem key={idx} value={state}>
            {state}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{handleErrorText()}</FormHelperText>
    </FormControl>
  );
};

export default State;
