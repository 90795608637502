export const validateUrl = (host) => {
  const environment = process.env.REACT_APP_ENV;
  const hostPath = host.split(".");

  if (environment === "local") {
    return hostPath[1] === "localhost:3000";
  }

  if (environment === "development") {
    return hostPath[1] === "dev" && hostPath[2] === "cco";
  }

  if (environment === "production") {
    return hostPath[1] === "cco";
  }
};
