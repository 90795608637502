import dayjs from "dayjs";

export const expirationDateIsInPast = (exp) => {
  const setMonth = parseInt(exp.slice(0, 2));
  const setYear = parseInt(exp.substring(2));

  const setDate = dayjs()
    .set("month", setMonth - 1)
    .set("year", parseInt(`${20}${setYear}`));

  return !setDate.isBefore(dayjs());
};
