import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { useCallCenterForm } from "../hooks/useCallCenterForm";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { red } from "@mui/material/colors";
import Modal from "@mui/material/Modal";
import styled from "@mui/system/styled";
import Typography from "@mui/material/Typography";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import { Button } from "@mui/material";

const style = {
  display: "flex",
  width: "400px",
  height: "200px",
  bgcolor: "background.paper",
  border: "2px solid",
  borderRadius: "8px",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  boxShadow: 24,
};

const FullPageBox = styled(Box)(() => ({
  height: "100vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

export default function NestedModal() {
  const { resetFormValues } = useCallCenterForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState(false);
  const isBeingSubmitted = useSelector(
    (state) => state.formSlice.isBeingSubmitted
  );
  const formError = useSelector((state) => state.formSlice.error);
  const hasBeenSuccessfullySubmitted = useSelector(
    (state) => state.formSlice.hasBeenSuccessfullySubmitted
  );

  useEffect(() => {
    if (isBeingSubmitted) {
      setModalOpen(true);
    }
  }, [isBeingSubmitted, modalOpen]);

  useEffect(() => {
    if (!formError && hasBeenSuccessfullySubmitted) {
      setSearchParams();
      resetFormValues();
    }
  }, [formError, hasBeenSuccessfullySubmitted, isBeingSubmitted]);

  const handleErrorClose = () => {
    setModalOpen(false);
  };

  const _handleFormError = () => {
    const errorMessage = formError.error_msg;
    return (
      <>
        <Typography variant="caption">
          {formError.transaction_id
            ? "Transaction Id: " + formError.transaction_id
            : formError}
        </Typography>
        <Typography variant="caption">
          {errorMessage
            ? errorMessage.substring(0, errorMessage.indexOf(","))
            : ""}
        </Typography>
      </>
    );
  };

  return (
    <Modal open={modalOpen}>
      <FullPageBox>
        <Box {...style}>
          {isBeingSubmitted ? (
            <>
              <Typography variant="p">
                Submitting order, please wait.
              </Typography>
              <CircularProgress />
            </>
          ) : (
            <>
              {formError ? (
                <>
                  <Typography variant="p">Order Failed</Typography>
                  <ReportIcon sx={{ fontSize: "48px", color: red[400] }} />
                  {_handleFormError()}
                  <Button onClick={handleErrorClose}>Close</Button>
                </>
              ) : (
                <>
                  <Typography variant="p">Order Completed</Typography>
                  <CheckCircleIcon sx={{ fontSize: "48px" }} color="success" />
                  <Typography variant="p">
                    Feel free to close this window
                  </Typography>
                </>
              )}
            </>
          )}
        </Box>
      </FullPageBox>
    </Modal>
  );
}
