import { ofType } from "redux-observable";
import { of, merge } from "rxjs";
import { mergeMap, switchMap, catchError } from "rxjs/operators";

import axios from "../../utils/axios";
import { handleAxiosError } from "../../utils/errorUtility";

import { submitForm, submitFormCompleted, submitFormFailed } from "./formSlice";

export const submitFormEpic = (action$) =>
  action$.pipe(
    ofType(submitForm),
    mergeMap(async (action) => {
      const response = await axios.post(`/api/v1/payment`, action.payload);
      if (response.data.message === "rejected") {
        throw new Error(JSON.stringify(response.data));
      }

      return response.data;
    }),
    switchMap((res) => [submitFormCompleted(res)]),
    catchError((error, source) =>
      merge(of(submitFormFailed(error.message)), source)
    )
  );

export default [submitFormEpic];
