import React, { Fragment } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';

import defaultTheme from './themes/default';

const Theme = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={defaultTheme}>
      <ThemeProvider theme={defaultTheme}>
        <Fragment>
          <CssBaseline />
          {children}
        </Fragment>
      </ThemeProvider>
    </MuiThemeProvider>
  </StyledEngineProvider>
);

export default Theme;
