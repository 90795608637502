import { z } from "zod";
import luhn from "fast-luhn";

import { expirationDateIsInPast } from "../../../utils/expirationDateIsInPast";

const formSchema = z.object({
  first_name: z.string().min(1, { message: "First Name is required" }),
  last_name: z.string().min(1, { message: "Last Name is required" }),
  state: z.string().min(1, { message: "State is required" }),
  city: z.string().min(1, { message: "City is required" }),
  address1: z.string().min(1, { message: "Address 1 is required" }),
  zip: z
    .string()
    .refine((value) => /(^\d{5}$)|(^\d{5}\d{4}$)/.test(value ?? ""), {
      message: "Zip Format Incorrect",
    }),
  email: z
    .string()
    .email(4, { message: "Email format Incorrect" })
    .optional()
    .or(z.literal("")),
  phone: z
    .string()
    .min(10, { message: "Phone must be at least 10 characters" }),
  card_number: z
    .string()
    .min(16, { message: "Card Number is required" })
    .refine((arg) => luhn(arg), {
      message: "Invalid Card Number",
      path: [],
    }),
  card_expiration: z
    .string()
    .min(4, { message: "Expiration is required" })
    .max(4)
    .refine((arg) => parseInt(arg.slice(0, 2)) <= 12, {
      message: "Month Exceeds 12",
      path: [],
    })
    .refine((arg) => parseInt(arg.slice(0, 2)) > 0, {
      message: "Month Starts at 1",
      path: [],
    })
    .refine((arg) => expirationDateIsInPast(arg), {
      message: "Date Can't be in the past",
      path: [],
    }),
  issuer: z.string(),
  card_cvv: z.string().min(3, { message: "CVV is required" }),
});

export const validateForm = (data) => {
  try {
    const formValidate = formSchema.safeParse(data);
    if (!formValidate.success) {
      return formValidate?.error?.issues;
    } else {
      return false;
    }
  } catch (e) {
    return e;
  }
};
