import React from 'react';
import { styled } from '@mui/system';

import Stack from '@mui/material/Stack';

const PageStack = styled(Stack)({
  display: 'flex',
  direction:'column',
  height: '100vh',
  width: '100%',
  overflow: 'hidden'
});

const CenteredLayout = ({ Component, ...props}) => {
  return (
    <PageStack>
      <Component />
    </PageStack>
  );
};

export default CenteredLayout;
