import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

export const initialState = {
  data: {},
  isBeingSubmitted: false,
  hasBeenSuccessfullySubmitted: false,
  error: undefined,
};

const parseError = (payload) => {
  try {
    return JSON.parse(payload);
  } catch (e) {
    return payload;
  }
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    submitForm: (state) => {
      state.isBeingSubmitted = true;
    },
    submitFormCompleted: (state, action) => {
      state.data = action.payload;
      state.error = undefined;
      state.isBeingSubmitted = false;
      state.hasBeenSuccessfullySubmitted = true;
    },
    submitFormFailed: (state, action) => {
      console.log("FAILED", action.payload);

      state.isBeingSubmitted = false;
      state.error = parseError(action.payload);
      state.hasBeenSuccessfullySubmitted = false;
    },
  },
});

export const { submitForm, submitFormCompleted, submitFormFailed } =
  formSlice.actions;

export default formSlice.reducer;
