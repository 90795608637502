import React, { useEffect } from "react";
import styled from "@mui/system/styled";
import { PatternFormat } from "react-number-format";
import { useCallCenterForm } from "../hooks/useCallCenterForm";

import TextField from "@mui/material/TextField";

const TextInput = styled(TextField)(() => ({
  padding: "4px",
}));

const CustomFormatZip = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  const { formValues } = useCallCenterForm();

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={formValues?.zip?.length > 5 ? "#####-####" : "######"}
    />
  );
});

const Zip = () => {
  const { errors, formValues, handleInputChange, handleInputFocus } =
    useCallCenterForm();

  useEffect(() => {}, [formValues]);

  const handleErrorText = () => {
    if (!errors?.zip) {
      return null;
    }

    return errors?.zip;
  };

  const customErrorHandler = (e) => {
    handleInputChange(e);
  };

  return (
    <TextInput
      sx={{ flex: 1 }}
      name="zip"
      label="Zip"
      onChange={customErrorHandler}
      onFocus={handleInputFocus}
      value={formValues?.zip}
      InputProps={{
        inputComponent: CustomFormatZip,
      }}
      helperText={handleErrorText()}
      error={!!errors?.zip}
    />
  );
};

export default Zip;
